var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["navbar:order:create"],
                  expression: "['navbar:order:create']",
                },
              ],
              staticStyle: {
                float: "right",
                padding: "0 10px",
                width: "160px",
                background: "green",
                color: "#fff",
                "text-align": "center",
              },
              attrs: { tag: "a", target: "_blank", to: { path: "/create" } },
            },
            [_vm._v("创建订单")]
          ),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: require("@/assets/logo/logo.png") },
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("退出")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "dark", content: "点击下载APP" },
            },
            [
              _c("img", {
                staticStyle: {
                  height: "40px",
                  "margin-right": "20px",
                  cursor: "pointer",
                },
                attrs: {
                  src: require("../../assets/images/qrcode.png"),
                  alt: "",
                },
                on: { click: _vm.getTenant },
              }),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
      _c("span", { staticClass: "title" }, [_vm._v("代驾管理平台")]),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.dialogVisible, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属租户", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        placeholder: "请选择租户",
                        size: "small",
                        filterable: "",
                      },
                      model: {
                        value: _vm.tenantId,
                        callback: function ($$v) {
                          _vm.tenantId = $$v
                        },
                        expression: "tenantId",
                      },
                    },
                    _vm._l(_vm.tenantList, function (dict) {
                      return _c("el-option", {
                        key: dict.id,
                        attrs: { label: dict.name, value: dict.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-around",
              },
            },
            [
              _c("el-card", [
                _c("h2", [_vm._v("司机端")]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-around",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.copyLink(
                              _vm.link + "?tenantId=" + _vm.tenantId
                            )
                          },
                        },
                      },
                      [_vm._v("复制链接")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadQR(1)
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c("vue-qr", {
                      ref: "qrCode1",
                      attrs: {
                        dotScale: 0.9,
                        margin: 0,
                        logoMargin: 5,
                        logoBackgroundColor: "#FFF",
                        text: _vm.link + "?tenantId=" + _vm.tenantId,
                        correctLevel: 1,
                        size: 128 * 3,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("el-card", [
                _c("h2", [_vm._v("老板端")]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-around",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.copyLink(_vm.link + "?tenantId=888")
                          },
                        },
                      },
                      [_vm._v("复制链接")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadQR(2)
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c("vue-qr", {
                      ref: "qrCode2",
                      attrs: {
                        dotScale: 0.9,
                        margin: 0,
                        logoMargin: 5,
                        logoBackgroundColor: "#FFF",
                        text: _vm.link + "?tenantId=888",
                        correctLevel: 1,
                        size: 128 * 3,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }