import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseTime, bd_decrypt } from '@/utils/index';
import AMap from 'AMap'; // 引入高德地图
import SelectItem from '@/components/SelectItem';
import { thermalMap } from '@/api/manager/thermalMap';
import { getEnabledCompanyList } from '@/api/manager/company';
export default {
  watch: {
    // 如果 `clientHeight` 发生改变，这个函数就会运行
    'amapObj.clientHeight': function amapObjClientHeight() {
      this.changeFixed(this.amapObj.clientHeight);
    }
  },
  data: function data() {
    return {
      listQuery: {
        tenantId: '',
        companyId: '',
        startTime: ''
      },
      companyId: null,
      company: [],
      city: '',
      lnglat: null,
      // 高德地图
      amapObj: {
        map: undefined,
        clientHeight: '',
        ruler: undefined,
        texts: []
      },
      isInit: true,
      fullscreenLoading: false,
      time_line: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      active: 20
    };
  },
  components: {
    SelectItem: SelectItem
  },
  created: function created() {
    this.listQuery.startTime = parseTime(new Date(), '{h}:{i}:{s}');
  },
  mounted: function mounted() {
    var _this = this;
    this.amapObj.clientHeight = "".concat(document.documentElement.clientHeight);
    this.mapInit();
    getEnabledCompanyList().then(function (res) {
      _this.company = res.data;
    });
    // this.search()
    // this.set_time(new Date().getHours())
  },

  methods: {
    upSelect: function upSelect(val) {
      this.listQuery.tenantId = val.tenantId;
      this.listQuery.companyId = val.companyId;
      if (this.isInit) {
        this.search();
        this.isInit = false;
      }
    },
    set_time: function set_time(val) {
      this.active = val;
      this.search();
    },
    // 动态修改样式
    changeFixed: function changeFixed(clientHeight) {
      this.$refs.amap.style.height = clientHeight + 'px';
    },
    mapInit: function mapInit() {
      var _this2 = this;
      this.amapObj.map = new AMap.Map('amap', {
        resizeEnable: true,
        // center: lnglat,
        zoom: 12,
        showIndoorMap: false // 是否在有矢量底图的时候自动展示室内地图
      });

      // this.amapObj.map.on('moveend', () => {
      //     var northwest = this.amapObj.map.getBounds().getNorthWest() //西北
      //     var eastsouth = this.amapObj.map.getBounds().getSouthEast() //东南
      //     console.log('move', northwest, eastsouth)
      // })
      if (!isSupportCanvas()) {
        alert('热力图仅对支持canvas的浏览器适用,您所使用的浏览器不能使用热力图功能,请换个浏览器试试~');
      }

      //详细的参数,可以查看heatmap.js的文档 http://www.patrick-wied.at/static/heatmapjs/docs.html
      //参数说明如下:
      /* visible 热力图是否显示,默认为true
          * opacity 热力图的透明度,分别对应heatmap.js的minOpacity和maxOpacity
          * radius 势力图的每个点的半径大小
          * gradient  {JSON} 热力图的渐变区间 . gradient如下所示
          *	{
          .2:'rgb(0, 255, 255)',
          .5:'rgb(0, 110, 255)',
          .8:'rgb(100, 0, 255)'
          }
          其中 key 表示插值的位置, 0-1
          value 为颜色值
          */

      this.amapObj.map.plugin(['AMap.Heatmap'], function () {
        //初始化heatmap对象
        _this2.amapObj.heatmap = new AMap.Heatmap(_this2.amapObj.map, {
          radius: 25,
          //给定半径
          opacity: [0, 0.8]
          /*,
          gradient:{
          0.5: 'blue',
          0.65: 'rgb(117,211,248)',
          0.7: 'rgb(0, 255, 0)',
          0.9: '#ffea00',
          1.0: 'red'
          }
          */
        });
        //设置数据集：该数据为北京部分“公园”数据
      });

      //判断浏览区是否支持canvas
      function isSupportCanvas() {
        var elem = document.createElement('canvas');
        return !!(elem.getContext && elem.getContext('2d'));
      }
      this.amapObj.map.on('complete', function () {});
    },
    setCity: function setCity() {
      var _this3 = this;
      for (var i = 0; i < this.company.length; i++) {
        if (this.company[i].id == this.listQuery.companyId) {
          this.city = this.company[i].area ? this.company[i].area : this.company[i].city;
          // 加载解析插件
          var geocoder = new AMap.Geocoder({});
          geocoder.getLocation(this.city, function (status, result) {
            if (status === 'complete' && result.geocodes.length) {
              _this3.lnglat = result.geocodes[0].location;
              console.log('lnglat', _this3.lnglat);
              _this3.amapObj.map.setCenter(_this3.lnglat);
            } else {
              console.log('根据地址查询位置失败');
            }
          });
          return;
        }
      }
    },
    search: function search() {
      var _this4 = this;
      if (!this.listQuery.startTime) {
        this.$message({
          message: '请选择时间',
          type: 'warning'
        });
        return;
      }
      this.fullscreenLoading = true;
      this.amapObj.map.clearMap();
      thermalMap({
        companyId: this.listQuery.companyId,
        startTime: this.listQuery.startTime
      }).then(function (res) {
        if (res.data.length > 0) {
          var data = res.data;
          data.map(function (item) {
            var tmp = bd_decrypt(item.lng, item.lat);
            item.lng = tmp.lng;
            item.lat = tmp.lat;
          });
          _this4.amapObj.heatmap.setDataSet({
            data: data,
            max: 5
          });
          _this4.amapObj.map.setCenter([data[0].lng, data[0].lat]);
          _this4.fullscreenLoading = false;
        } else {
          _this4.fullscreenLoading = false;
          _this4.amapObj.heatmap.setDataSet({
            data: [],
            max: 5
          });
          // 没有查询到司机数据，将地图移动到对应城市
          _this4.setCity();
        }
      });
    }
  }
};