import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AMap from 'AMap'; // 引入高德地图
import AMapUI from 'AMapUI';
import { dispatch, driver_info } from '@/api/driving/manager';
import { company_get_drop_list } from '@/api/manager/company';
// import { getEnabledCompanyList } from '@/api/manager/company'
import { bd_decrypt } from '@/utils/index';
export default {
  watch: {
    // 如果 `clientHeight` 发生改变，这个函数就会运行
    'amapObj.clientHeight': function amapObjClientHeight() {
      this.changeFixed(this.amapObj.clientHeight);
    }
  },
  data: function data() {
    return {
      driverName: '',
      companyId: null,
      company: [],
      city: '',
      lnglat: null,
      // 高德地图
      amapObj: {
        map: undefined,
        clientHeight: '',
        ruler: undefined,
        texts: []
      },
      listType: [{
        name: '空闲',
        value: 'FREE',
        key: 'freeWorkers',
        color: '#70C646'
      }, {
        name: '已接单',
        value: 'ACCEPT_ORDER',
        key: 'acceptOrderWorker',
        color: '#DEC703'
      }, {
        name: '前往预约地',
        value: 'GO_TO_RESERVATION_PLACE',
        key: 'goToReservationPlaceWorker',
        color: '#EDA41C'
      }, {
        name: '到达预约地',
        value: 'GET_TO_RESERVATION_PLACE',
        key: 'getToReservationPlaceWorker',
        color: '#EE7E15'
      }, {
        name: '前往目的地',
        value: 'GO_TO_DESTINATION',
        key: 'goToDestinationWorker',
        color: '#BA0101'
      }, {
        name: '未听单',
        value: 'UNLISTED',
        key: 'unlistedWorker',
        color: '#5A9EF8'
      }],
      typeColor: {
        freeWorkers: '#70C646',
        acceptOrderWorker: '#DEC703',
        goToReservationPlaceWorker: '#EDA41C',
        getToReservationPlaceWorker: '#EE7E15',
        goToDestinationWorker: '#BA0101',
        unlistedWorker: '#5A9EF8'
      },
      checkList: ['FREE', 'ACCEPT_ORDER', 'GO_TO_RESERVATION_PLACE', 'GET_TO_RESERVATION_PLACE', 'GO_TO_DESTINATION'],
      driverList: {
        freeWorkers: [],
        acceptOrderWorker: [],
        goToReservationPlaceWorker: [],
        getToReservationPlaceWorker: [],
        goToDestinationWorker: [],
        unlistedWorker: []
      },
      fullscreenLoading: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.amapObj.clientHeight = "".concat(document.documentElement.clientHeight);
    this.mapInit();
    company_get_drop_list().then(function (res) {
      _this.company = res.data;
      _this.companyId = _this.company[0].id;
      _this.setCity();
      _this.search();
    });
  },
  methods: {
    // 动态修改样式
    changeFixed: function changeFixed(clientHeight) {
      this.$refs.amap.style.height = clientHeight + 'px';
    },
    mapInit: function mapInit() {
      var _this2 = this;
      this.amapObj.map = new AMap.Map('amap', {
        resizeEnable: true,
        // center: lnglat,
        zoom: 14,
        showIndoorMap: false // 是否在有矢量底图的时候自动展示室内地图
      });

      this.amapObj.map.on('complete', function () {
        _this2.amapObj.ruler = new AMap.RangingTool(_this2.amapObj.map);
        AMapUI.loadUI(['control/BasicControl'], function (BasicControl) {
          //图层切换控件
          _this2.amapObj.map.addControl(new BasicControl.LayerSwitcher({
            position: 'rb'
          }));
        });
      });
    },
    rulerOn: function rulerOn() {
      this.amapObj.ruler.turnOn();
    },
    setCity: function setCity() {
      var _this3 = this;
      for (var i = 0; i < this.company.length; i++) {
        if (this.company[i].id == this.companyId) {
          this.city = this.company[i].area ? this.company[i].city + this.company[i].area : this.company[i].city;
          // 加载解析插件
          var geocoder = new AMap.Geocoder({});
          geocoder.getLocation(this.city, function (status, result) {
            if (status === 'complete' && result.geocodes.length) {
              _this3.lnglat = result.geocodes[0].location;
              console.log('lnglat', _this3.lnglat);
              _this3.amapObj.map.setCenter(_this3.lnglat);
              _this3.search();
            } else {
              console.log('根据地址查询位置失败');
            }
          });
          return;
        }
      }
    },
    search: function search() {
      var _this4 = this;
      if (this.checkList.length == 0) {
        console.log(this.checkList.length);
        this.$message.error('请选择要查询的司机状态');
        return;
      }
      this.fullscreenLoading = true;
      this.amapObj.map.clearMap();
      this.amapObj.texts = [];
      var _setCenter = true;
      dispatch({
        status: this.checkList + '',
        companyId: this.companyId
      }).then(function (res) {
        _this4.fullscreenLoading = false;
        _this4.driverList = res.data;
        var _loop = function _loop(key) {
          _this4.driverList[key].map(function (item) {
            // AMap.convertFrom(
            //     [item.driverLongitude, item.driverLatitude],

            //     'baidu',
            //     (status, result) => {
            //         if (result.info === 'ok') {
            // 创建一个 Text 实例：
            var amaplntlat = bd_decrypt(item.driverLongitude, item.driverLatitude);
            var text = new AMap.Text({
              // position: result.locations[0],
              position: [amaplntlat.lng, amaplntlat.lat],
              anchor: 'center',
              // 设置文本标记锚点
              text: item.driverName,
              extData: item,
              style: {
                background: _this4.typeColor[key],
                color: '#FFF',
                'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                padding: '3px'
              }
              // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            });
            // 将点标记加入数组用于清除点标记
            _this4.amapObj.texts.push(text);
            // 将创建的点标记添加到已有的地图实例：
            _this4.amapObj.map.add(text);

            // 鼠标点击texts弹出自定义的信息窗体
            AMap.event.addListener(text, 'click', function (e) {
              console.log(e.target.getExtData());
              driver_info({
                driverId: e.target.getExtData().driverId
              }).then(function (res) {
                console.log(res);
                // 创建 infoWindow 实例
                var infoWindow = new AMap.InfoWindow({
                  content: "<h4>".concat(res.data.name, "</h4><div>\u4ECA\u65E5\u5B8C\u6210:").concat(res.data.todayCompeteOrder, "\u5355</div><div>\u672C\u6708\u5B8C\u6210:").concat(res.data.toMothCompeteOrder, "\u5355</div><div>\u4ECA\u65E5\u6536\u5165:").concat(res.data.todayIncome / 100, "\u5143</div><div>\u672C\u6708\u6536\u5165:").concat(res.data.toMothIncome / 100, "\u5143</div><div>\u53F8\u673A\u7535\u8BDD:").concat(res.data.phone, "</div><div>\u5B9A\u4F4D\u65F6\u95F4:").concat(res.data.locationTime, "</div>"),
                  anchor: 'bottom-left',
                  offset: new AMap.Pixel(0, -15)
                });
                infoWindow.open(_this4.amapObj.map, text.getPosition());
              });
            });
            if (item.driverName.indexOf(_this4.driverName) > -1) {
              if (_setCenter) {
                console.log('center');
                _this4.amapObj.map.setCenter(text.getPosition());
                _setCenter = false;
              }
            }
            //         }
            //     }
            // )
          });
        };
        for (var key in _this4.driverList) {
          _loop(key);
        }
      });
    }
  }
};