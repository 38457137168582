import _toConsumableArray from "/var/lib/jenkins/workspace/pre_\u4EE3\u9A7E\u7BA1\u7406\u9875\u9762/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src
import AMap from 'AMap'; // 引入高德地图

// import { isvalidPhone } from '../../utils/validate'
import { getNearDrivers, orderCreateBatch, validCustomerCoupons, drop_down_list, charge_fence } from '@/api/driving/manager';
import { company_get_drop_list } from '@/api/manager/company';
import { customerInfoPhone } from '@/api/manager/customer';
import { convertGCJ02ToBD09, bd_decrypt, parseTime } from '@/utils/index';
import { customer_order, customer_unpaid_order, estimated_fee, create_check_car, check_car_estimated_fee } from '@/api/manager/order';
// import {getEnabledCompanyList} from '@/api/manager/company'
// import { getDriverPosition } from "@/api/ReportLocation";
// var validPhone = (rule, value, callback) => {
//     if (!value) {
//         callback(new Error('请输入电话号码'))
//     } else if (!isvalidPhone(value)) {
//         callback(new Error('请输入正确的电话号码'))
//     } else {
//         callback()
//     }
// }
export default {
  name: 'Created',
  computed: {
    btnDisabled: function btnDisabled() {
      // if (!isvalidPhone(this.form.customerPhone)) {
      //     return true
      // }
      if (!this.isSearchPhone) {
        return true;
      }
      if (this.form.customerName == '') {
        return true;
      }
      if (!this.form.driverCount) {
        return true;
      }
      if (this.mapObj.marker.start == undefined) {
        return true;
      }
      if (this.form.customerPhone.length != 11 && this.form.customerPhone.length != 8 && this.form.customerPhone.length != 12 && this.form.customerPhone.length != 7) {
        return true;
      }
      if (this.multipleSelection.length > 0 && this.multipleSelection.length != this.form.driverCount) {
        return true;
      }
      return false;
    }
  },
  data: function data() {
    return {
      businessType: {
        chargeType: ''
      },
      isNow: true,
      // 是否是立刻出发
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          var ten = new Date().getTime() + 10 * 60 * 1000 - 24 * 60 * 60 * 1000;
          var timeInFortyEightHours = ten + 72 * 60 * 60 * 1000 - 60 * 1000;
          var maxTime = timeInFortyEightHours;
          var minTime = ten;
          return time.getTime() > maxTime || time.getTime() < minTime;
        },
        selectableRange: '00:00:00 - 23:59:59'
      },
      fee: 0,
      // 预估金额
      lineDistance: 0,
      // 预估距离
      customerOrderList: [],
      // 用户进行中的订单信息
      isSearchPhone: false,
      // 是否查询过电话
      btnDis: false,
      // 高德地图
      mapObj: {
        map: undefined,
        clientHeight: '',
        placeSearch: undefined,
        marker: {
          start: undefined,
          end: undefined
        },
        markers: []
        // driving: undefined
      },

      lnglat: null,
      tableData: [],
      // 司机列表
      multipleSelection: [],
      // 司机ID列表
      chargeIdList: {},
      radio: 5,
      // 距离
      // 表单
      form: {
        reserveTime: '',
        // 预约时间
        companyId: '',
        start: '',
        end: '',
        customerPhone: '',
        customerName: '',
        driverCount: 1,
        billingMode: 'TIME',
        textarea: '',
        customerCouponId: null,
        businessTypeJoinId: null,
        checkCarYear: 'TEN_YEAR_DOWN'
      },
      // 验证规则
      rules: {
        companyId: [{
          required: true,
          message: '请选择公司',
          trigger: 'blur'
        }],
        start: [{
          required: true,
          message: '请输入起点'
        }],
        reserveTime: [{
          required: !this.isNow,
          message: '请选择预约时间'
        }],
        customerPhone: [{
          required: true,
          // validator: validPhone,
          message: '请输入客户电话'
        }],
        driverCount: [{
          required: true,
          message: '请输入司机数量'
        }],
        customerName: [{
          required: true,
          message: '请输入客户姓名'
        }]
      },
      company: [],
      temporaryFeeIsUsing: '',
      disabled: '',
      city: '0311',
      // 表达输入类型
      formType: {
        inputType: undefined
      },
      // 搜索结果框样式
      panelClass: '',
      // 优惠券
      couponlist: [],
      options: [],
      top: '190px'
      // address: "",
      // driver: undefined,
      // style: [
      //     {
      //         url:
      //             "https://share.xiaozhuanggx.com/api/msg/complaint/getFile/36b212f9f0583d7b3f11281978cd61b3",
      //         anchor: new AMap.Pixel(12, 12),
      //         size: new AMap.Size(22, 22)
      //     },
      //     {
      //         url:
      //             "https://a.amap.com/jsapi_demos/static/images/mass1.png",
      //         anchor: new AMap.Pixel(8, 8),
      //         size: new AMap.Size(14, 14)
      //     }
      // ]
    };
  },

  watch: {
    'form.reserveTime': {
      handler: function handler(newValue, oldValue) {
        console.log('newValue', newValue, 'oldValue', oldValue);
        if (newValue) {
          var date = new Date();
          var min = date.getMinutes();
          date.setMinutes(min + 10); //这里加1分钟，是为了解决值改变后，系统秒数就过期限制了，无法点击“此刻”按钮， 如果监听 “系统时间”的改变，则会影响性能。
          var nowDate = parseTime(date, '{h}:{i}:{s}');
          if (parseTime(date, '{y}-{m}-{d}') === parseTime(newValue, '{y}-{m}-{d}')) {
            if (new Date(this.form.reserveTime) < date) {
              this.form.reserveTime = parseTime(date, '{y}-{m}-{d} {h}:{i}:{s}');
            }
            this.pickerOptions.selectableRange = nowDate + ' - ' + '23:59:59';
            return;
          }
          if (parseTime(new Date().getTime() + 10 * 60 * 1000 + 48 * 60 * 60 * 1000 - 60 * 1000 * 2, '{y}-{m}-{d}') === parseTime(newValue, '{y}-{m}-{d}')) {
            if (new Date(this.form.reserveTime) >= new Date().getTime() + 10 * 60 * 1000 - 24 * 60 * 60 * 1000 + 72 * 60 * 60 * 1000 - 60 * 1000) {
              this.form.reserveTime = parseTime(new Date().getTime() + 10 * 60 * 1000 - 24 * 60 * 60 * 1000 + 72 * 60 * 60 * 1000 - 60 * 1000);
            }
            this.pickerOptions.selectableRange = '00:00:00' + ' - ' + nowDate;
            return;
          }
          this.pickerOptions.selectableRange = '00:00:00' + ' - ' + '23:59:59';
        }
      },
      deep: true,
      immediate: true
    },
    // 如果 `clientHeight` 发生改变，这个函数就会运行
    'mapObj.clientHeight': function mapObjClientHeight() {
      this.changeFixed(this.mapObj.clientHeight);
    },
    'form.start': function formStart(val) {
      var _this = this;
      if (val == '') {
        this.mapObj.placeSearch.clear();
        this.mapObj.map.clearInfoWindow();
        if (this.mapObj.marker.start) {
          this.mapObj.map.remove([this.mapObj.marker.start]);
          if (this.mapObj.markers[0]) {
            this.mapObj.markers.map(function (ele) {
              _this.mapObj.map.remove(ele);
            });
          }
          this.mapObj.marker.start = undefined;
        }
      }
    },
    'form.end': function formEnd(val) {
      if (val == '') {
        this.mapObj.placeSearch.clear();
        this.mapObj.map.clearInfoWindow();
        if (this.mapObj.marker.end) {
          this.mapObj.map.remove([this.mapObj.marker.end]);
          this.mapObj.marker.end = undefined;
        }
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.mapObj.clientHeight = "".concat(document.documentElement.clientHeight);
    this.mapInit();
    company_get_drop_list({
      isUsing: true
    }).then(function (res) {
      _this2.company = res.data;
      _this2.form.companyId = _this2.company[0].id;
      _this2.setCity();
    });
  },
  methods: {
    setTime: function setTime() {
      if (this.isNow) {
        this.form.reserveTime = undefined;
      } else {
        this.form.reserveTime = parseTime(new Date().getTime() + 10 * 60 * 1000);
      }
    },
    setChargeType: function setChargeType() {
      var _this3 = this;
      var tmp = this.options.filter(function (item) {
        return item.id == _this3.form.businessTypeJoinId;
      });
      this.businessType = tmp[0];
      if (this.mapObj.marker.start && this.mapObj.marker.end && this.businessType.chargeType != 'CHECK_CAR') {
        this.setFee();
        return;
      }
      if (this.businessType.chargeType == 'CHECK_CAR') {
        this.setFee();
        return;
      }
    },
    mapInit: function mapInit() {
      var _this4 = this;
      this.mapObj.map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: 8,
        showIndoorMap: false // 是否在有矢量底图的时候自动展示室内地图
      });

      // this.mapObj.map.add(markers)
      // //实时路况图层
      // var trafficLayer = new AMap.TileLayer.Traffic({});
      // this.mapObj.map.add(trafficLayer); //添加图层到地图
      AMap.service(['AMap.PlaceSearch'], function () {
        //构造地点查询类
        _this4.mapObj.placeSearch = new AMap.PlaceSearch({
          pageSize: 5,
          // 单页显示结果条数
          city: _this4.city,
          // 兴趣点城市
          citylimit: true,
          //是否强制限制在设置的城市内搜索
          map: _this4.mapObj.map,
          // 展现结果的地图实例
          panel: 'panel',
          // 结果列表将在此容器中进行展示。
          autoFitView: true,
          // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          renderStyle: 'default'
        });
        AMap.event.addListener(_this4.mapObj.placeSearch, 'listElementClick', _this4.onComplete);
        AMap.event.addListener(_this4.mapObj.placeSearch, 'markerClick', _this4.onComplete);
      });
      AMap.plugin('AMap.Driving', function () {
        //异步加载插件
        _this4.mapObj.driving = new AMap.Driving({
          map: _this4.mapObj.map,
          policy: 10
        });
      });
      // 加载解析插件
      var geocoder = new AMap.Geocoder({});
      //创建右键菜单
      var contextMenu = new AMap.ContextMenu();
      var contextMenuPositon = new AMap.LngLat(116.397, 39.918);
      //右键添加Marker标记
      contextMenu.addItem('设为起点', function () {
        geocoder.getAddress(contextMenuPositon, function (status, result) {
          if (status === 'complete' && result.regeocode) {
            var _address = result.regeocode.formattedAddress.replace(result.regeocode.addressComponent.province + result.regeocode.addressComponent.city, '');
            var address = _address;
            _this4.formType.inputType = 'start';
            if (_this4.mapObj.marker.start) {
              _this4.mapObj.map.remove([_this4.mapObj.marker.start]);
              _this4.mapObj.marker.start = undefined;
            }
            _this4.onComplete({
              data: {
                name: address,
                location: contextMenuPositon
              }
            });
          } else {
            _this4.$message.error('根据经纬度查询地址失败');
          }
        });
      }, 0);
      contextMenu.addItem('设为终点', function () {
        geocoder.getAddress(contextMenuPositon, function (status, result) {
          if (status === 'complete' && result.regeocode) {
            var _address = result.regeocode.formattedAddress.replace(result.regeocode.addressComponent.province + result.regeocode.addressComponent.city, '');
            var address = _address;
            _this4.formType.inputType = 'end';
            if (_this4.mapObj.marker.end) {
              _this4.mapObj.map.remove([_this4.mapObj.marker.end]);
              _this4.mapObj.marker.end = undefined;
            }
            _this4.onComplete({
              data: {
                name: address,
                location: contextMenuPositon
              }
            });
          } else {
            _this4.$message.error('根据经纬度查询地址失败');
          }
        });
      }, 1);
      //地图绑定鼠标右击事件——弹出右键菜单
      this.mapObj.map.on('rightclick', function (e) {
        contextMenu.open(_this4.mapObj.map, e.lnglat);
        contextMenuPositon = e.lnglat;
      });
      contextMenu.open(this.mapObj.map, contextMenuPositon);
    },
    // 动态修改样式
    changeFixed: function changeFixed(clientHeight) {
      this.$refs.container.style.height = clientHeight + 'px';
    },
    getDrivers: function getDrivers() {
      var _this5 = this;
      var lnglat = convertGCJ02ToBD09(this.mapObj.marker.start.getPosition().lat, this.mapObj.marker.start.getPosition().lng);
      getNearDrivers({
        companyId: this.form.companyId,
        latitude: lnglat.lat,
        longitude: lnglat.lng,
        radius: this.radio * 1000
      }).then(function (res) {
        if (_this5.mapObj.markers[0]) {
          _this5.mapObj.markers.map(function (ele) {
            _this5.mapObj.map.remove(ele);
          });
        }
        _this5.mapObj.markers = [];
        _this5.tableData = res.data;
        _this5.tableData.map(function (item) {
          // AMap.convertFrom(
          //     [item.longitude, item.latitude],

          //     'baidu',
          //     (status, result) => {
          //         if (result.info === 'ok') {
          // 创建一个 Marker 实例：
          var amaplntlat = bd_decrypt(item.longitude, item.latitude);
          var marker = new AMap.Marker({
            // position: [result.locations[0]],
            position: [amaplntlat.lng, amaplntlat.lat],
            icon: require('@/assets/images/driver.png'),
            extData: item
            // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          });
          // 将点标记加入数组用于清除点标记
          _this5.mapObj.markers.push(marker);
          // 将创建的点标记添加到已有的地图实例：
          _this5.mapObj.map.add(marker);
          // // 创建 infoWindow 实例
          var infoWindow = new AMap.InfoWindow({
            content: "<div>[".concat(item.driverName, "]").concat(item.driverPhone, "</div>"),
            anchor: 'top-center'
          });
          //鼠标点击marker弹出自定义的信息窗体
          AMap.event.addListener(marker, 'mousemove', function () {
            infoWindow.open(_this5.mapObj.map, marker.getPosition());
          });
          AMap.event.addListener(marker, 'mouseout', function () {
            infoWindow.close();
          });
          //         }
          //     }
          // )
        });
      });
    },
    // 地图搜索
    search: function search(value, type) {
      this.panelClass = type == 'start' ? 'start' : 'end'; // 设置搜索结果框高度

      if (value != '') {
        this.formType.inputType = type;
        if (this.mapObj.marker[type]) {
          this.mapObj.map.remove([this.mapObj.marker[type]]);
          this.mapObj.marker[type] = undefined;
        }
        this.mapObj.placeSearch.setPageIndex(1);
        this.mapObj.placeSearch.search(value);
      }
    },
    // 查询结果点击
    onComplete: function onComplete(data) {
      var _this6 = this;
      this.form[this.formType.inputType] = data.data.name;
      this.mapObj.placeSearch.clear();
      this.addMarker(data.data.location.lng, data.data.location.lat);
      if (this.mapObj.marker.start && this.mapObj.marker.end) {
        var start = this.mapObj.marker.start.getPosition();
        var end = this.mapObj.marker.end.getPosition();
        this.mapObj.driving.search(new AMap.LngLat(start.lng, start.lat), new AMap.LngLat(end.lng, end.lat), function (status, result) {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          if (status === 'complete') {
            var routes = result.routes;
            routes = routes.sort(function (a, b) {
              return a.distance - b.distance;
            });
            console.log('res', routes);
            _this6.lineDistance = routes[0].distance;
            _this6.mapObj.map.clearInfoWindow();
            _this6.mapObj.map.remove([_this6.mapObj.marker.start, _this6.mapObj.marker.end]);
          }
        });
        this.setFee();
      }
    },
    // 预估费用
    setFee: function setFee() {
      var _this7 = this;
      var startLatLng = convertGCJ02ToBD09(this.mapObj.marker.start.getPosition().lat, this.mapObj.marker.start.getPosition().lng);
      var endLatLng = convertGCJ02ToBD09(this.mapObj.marker.end.getPosition().lat, this.mapObj.marker.end.getPosition().lng);
      if (this.businessType.chargeType == 'CHECK_CAR') {
        check_car_estimated_fee({
          chargeId: this.businessType.chargeId,
          checkCarYear: this.form.checkCarYear
        }).then(function (res) {
          _this7.fee = res.data / 100;
        });
      } else {
        estimated_fee({
          chargeId: this.businessType.chargeId,
          chargeType: this.businessType.chargeType,
          origin: startLatLng.lng + ',' + startLatLng.lat,
          destination: endLatLng.lng + ',' + endLatLng.lat
        }).then(function (res) {
          _this7.fee = res.data / 100;
        });
      }
    },
    // 标记地图点
    addMarker: function addMarker(lng, lat) {
      this.mapObj.marker[this.formType.inputType] = new AMap.Marker({
        icon: this.formType.inputType == 'start' ? 'https://webapi.amap.com/theme/v1.3/markers/n/start.png' : 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
        position: [lng, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      this.mapObj.map.add(this.mapObj.marker[this.formType.inputType]);
      this.formType.inputType == 'start' ? this.getDrivers() : null;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    isDisabled: function isDisabled(row) {
      if (this.multipleSelection.length >= this.form.driverCount) {
        for (var i = 0; i < this.multipleSelection.length; i++) {
          if (this.multipleSelection[i].driverPhone == row.driverPhone) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    },
    setName: function setName() {
      this.form.customerPhone = this.form.customerPhone.replace(/[^\d]/g, '');
      this.couponlist = [];
      this.isSearchPhone = false;
      this.form.customerCouponId = null;
      if (this.form.customerPhone == '') {
        this.form.customerName = '';
      }
      if (this.form.customerPhone.length == 11 || this.form.customerPhone.length == 8 || this.form.customerPhone.length == 7 || this.form.customerPhone.length == 12) {
        this.form.customerName = '客户' + this.form.customerPhone.slice(-4);
      }
    },
    createOrder: function createOrder() {
      var _this8 = this;
      if (!this.mapObj.marker.end) {
        this.form.end = null;
      }
      var driverIds = [];
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.map(function (item) {
          driverIds.push(item.driverId);
        });
      }
      var startLatLng = convertGCJ02ToBD09(this.mapObj.marker.start.getPosition().lat, this.mapObj.marker.start.getPosition().lng);
      var endLatLng = {};
      if (this.mapObj.marker.end) {
        endLatLng = convertGCJ02ToBD09(this.mapObj.marker.end.getPosition().lat, this.mapObj.marker.end.getPosition().lng);
      }
      this.btnDis = true;
      if (this.businessType.chargeType != 'CHECK_CAR') {
        orderCreateBatch({
          businessTypeJoinId: this.form.businessTypeJoinId,
          reserveTime: this.isNow ? undefined : this.form.reserveTime.length == 19 ? this.form.reserveTime : this.form.reserveTime + ':00',
          driverCount: this.form.driverCount,
          customerName: this.form.customerName,
          customerPhone: this.form.customerPhone,
          destinationLat: this.mapObj.marker.end ? endLatLng.lat : null,
          destinationLon: this.mapObj.marker.end ? endLatLng.lng : null,
          destinationName: this.form.end == '' ? null : this.form.end,
          driverIds: driverIds,
          startLocationLat: this.mapObj.marker.start ? startLatLng.lat : null,
          startLocationLon: this.mapObj.marker.start ? startLatLng.lng : null,
          startLocationName: this.form.start,
          customerCouponId: this.form.driverCount == 1 ? this.form.customerCouponId : null
        }).then(function () {
          _this8.btnDis = false;
          _this8.$message.success('创建成功');
          _this8.reset();
        }).catch(function () {
          _this8.btnDis = false;
        });
      } else {
        create_check_car({
          businessTypeJoinId: this.form.businessTypeJoinId,
          reserveTime: this.isNow ? undefined : this.form.reserveTime.length == 19 ? this.form.reserveTime : this.form.reserveTime + ':00',
          driverCount: 1,
          checkCarYear: this.form.checkCarYear,
          customerName: this.form.customerName,
          customerPhone: this.form.customerPhone,
          destinationLatitude: null,
          destinationLongitude: null,
          destinationName: null,
          startLocationLatitude: this.mapObj.marker.start ? startLatLng.lat : null,
          startLocationLongitude: this.mapObj.marker.start ? startLatLng.lng : null,
          startLocationName: this.form.start,
          customerCouponId: null
        }).then(function () {
          _this8.btnDis = false;
          _this8.$message.success('创建成功');
          _this8.reset();
        }).catch(function () {
          _this8.btnDis = false;
        });
      }
    },
    reset: function reset() {
      var _this9 = this;
      this.multipleSelection = [];
      if (this.mapObj.markers[0]) {
        this.mapObj.markers.map(function (ele) {
          _this9.mapObj.map.remove(ele);
        });
      }
      this.mapObj.placeSearch.clear();
      this.mapObj.map.clearInfoWindow();
      if (this.mapObj.marker.end) {
        this.mapObj.map.remove([this.mapObj.marker.end]);
        this.mapObj.marker.end = undefined;
      }
      this.mapObj.map.remove([this.mapObj.marker.start]);
      this.mapObj.marker.start = undefined;
      this.$refs.form.resetFields();
      this.form.customerName = '';
      this.form.end = '';
      this.couponlist = [];
      this.form.customerCouponId = null;
      this.form.companyId = this.company[0].id;
      this.form.driverCount = 1;
      this.isNow = true;
      this.form.reserveTime = undefined;
      this.setCity();
    },
    // 搜索客户电话
    searchPhone: function searchPhone() {
      var _this10 = this;
      charge_fence({
        fenceChargeId: this.businessType.chargeId
      }).then(function (res) {
        console.log(res.data);
        _this10.temporaryFeeIsUsing = res.data.temporaryFeeIsUsing;
        sessionStorage.setItem('temporaryFeeIsUsing', res.data.temporaryFeeIsUsing);
      });
      this.customerOrderList = [];
      this.$refs.form.validateField('customerPhone', function () {
        if (_this10.form.customerPhone.length != 11 && _this10.form.customerPhone.length != 8 && _this10.form.customerPhone.length != 12 && _this10.form.customerPhone.length != 7) {
          _this10.$message.error('请输入正确电话号码');
        } else {
          customer_order({
            companyId: _this10.form.companyId,
            customerPhone: _this10.form.customerPhone
          }).then(function (res) {
            res.data.map(function (item) {
              _this10.customerOrderList.push(item);
            });
          });
          customer_unpaid_order({
            companyId: _this10.form.companyId,
            customerPhone: _this10.form.customerPhone
          }).then(function (res) {
            res.data.map(function (item) {
              _this10.customerOrderList.push(item);
            });
          });
          // charge_fence({
          //     fenceChargeId: this.businessType.chargeId
          // }).then(res => {
          //     console.log(res.data)
          //     this.temporaryFeeIsUsing = res.data.temporaryFeeIsUsing
          // })
          customerInfoPhone({
            companyId: _this10.form.companyId,
            phone: _this10.form.customerPhone
          }).then(function (response) {
            if (response.data) {
              _this10.form.customerName = response.data.name;
            }
          });
          _this10.couponlist = [];
          _this10.form.customerCouponId = null;
          validCustomerCoupons({
            companyId: _this10.form.companyId,
            phone: _this10.form.customerPhone
          }).then(function (res) {
            _this10.isSearchPhone = true;
            if (res.data.length > 0) {
              var tmp = []; // 可用优惠券
              res.data.map(function (item) {
                if (item.whetherAvailable == true) {
                  var disabled = '';
                  if (sessionStorage.getItem('temporaryFeeIsUsing') == 'true') {
                    if (item.isWeather) {
                      disabled = true;
                    } else {
                      disabled = false;
                      console.log(2);
                      tmp.push(item);
                    }
                  } else {
                    disabled = false;
                    tmp.push(item);
                  }
                  _this10.couponlist.push({
                    value: item.customerCouponId,
                    label: item.couponsName + '(' + item.amount / 100 + '元)',
                    disabled: disabled
                  });
                }
              });
              if (tmp.length > 0) {
                var _tmp = []; // 最大值优惠券
                var max = Math.max.apply(Math, _toConsumableArray(tmp.map(function (item) {
                  return item.amount;
                })));
                tmp.map(function (item) {
                  if (item.amount == max) {
                    _tmp.push(item);
                  }
                });
                if (_tmp.length > 0) {
                  _tmp.sort(function (a, b) {
                    return b.invalidTime - a.invalidTime;
                  });
                  _this10.form.customerCouponId = _tmp[0].customerCouponId;
                }
              }
            } else {
              // this.$message.error('没有可用优惠券')
            }
          });
        }
      });
    },
    setCity: function setCity() {
      var _this11 = this;
      if (this.form.start || this.form.end) {
        this.multipleSelection = [];
        if (this.mapObj.markers[0]) {
          this.mapObj.markers.map(function (ele) {
            _this11.mapObj.map.remove(ele);
          });
          this.mapObj.map.remove([this.mapObj.marker.start]);
        }
        this.mapObj.placeSearch.clear();
        this.mapObj.map.clearInfoWindow();
        if (this.mapObj.marker.end) {
          this.mapObj.map.remove([this.mapObj.marker.end]);
          this.mapObj.marker.end = undefined;
        }
        this.mapObj.marker.start = undefined;
        this.form.end = '';
        this.form.start = '';
      }
      drop_down_list({
        companyId: this.form.companyId
      }).then(function (res) {
        _this11.options = res.data;
        _this11.form.businessTypeJoinId = null;
        if (_this11.options.length > 0) {
          _this11.form.businessTypeJoinId = _this11.options[0].id;
          _this11.businessType = _this11.options[0];
        }
      });
      if (this.form.customerPhone) {
        this.searchPhone();
      }
      for (var i = 0; i < this.company.length; i++) {
        if (this.company[i].id == this.form.companyId) {
          this.city = this.company[i].city;
          var areaCode = this.company[i].areaCode;
          this.mapObj.map.setCity(areaCode ? areaCode : this.city, function () {
            _this11.mapObj.map.setZoom(12);
          });
          AMap.service(['AMap.PlaceSearch'], function () {
            //构造地点查询类
            _this11.mapObj.placeSearch = new AMap.PlaceSearch({
              pageSize: 5,
              // 单页显示结果条数
              city: _this11.city,
              // 兴趣点城市
              citylimit: true,
              //是否强制限制在设置的城市内搜索
              map: _this11.mapObj.map,
              // 展现结果的地图实例
              panel: 'panel',
              // 结果列表将在此容器中进行展示。
              autoFitView: true,
              // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
              renderStyle: 'default'
            });
            AMap.event.addListener(_this11.mapObj.placeSearch, 'listElementClick', _this11.onComplete);
            AMap.event.addListener(_this11.mapObj.placeSearch, 'markerClick', _this11.onComplete);
          });
          return;
        }
      }
    }
  }
};