import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import { xiaoka_piccode, xiaoka_dl } from '@/api/driving/manager';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  //  baseURL: `http://localhost:14530/driving/`,
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 100000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  // let each request carry token
  // ['X-Token'] is a custom headers key
  // please modify it according to the actual situation

  config.headers['x-auth-token'] = getToken() || null;
  return config;
}, function (error) {
  // do something with request error
  // console.log(error) // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  if ('x-auth-token' in response.headers) {
    store.dispatch('user/setToken', response.headers['x-auth-token']).then(function () {});
  }
  var res = response.data;
  if (res.code) {
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 401) {
        // to re-login
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      }
      if (res.code === 4000) {
        xiaoka_piccode().then(function (resp) {
          var str = "<img src=\"".concat(resp.data.pic, "\">");
          MessageBox.prompt(str, '请输入验证码', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true
          }).then(function (_ref) {
            var value = _ref.value;
            xiaoka_dl({
              code: value,
              cookie: resp.data.cookie,
              timestamp: resp.data.timestamp
            });
          }).catch(function () {});
        });
      }
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  } else {
    return res;
  }
}, function (error) {
  Message({
    message: error,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;